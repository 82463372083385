<template>
  <div>
    {{ showFoldersInSearch ? scope.row.path + ' / ' + scope.row.name : scope.row.name }}
  </div>
</template>

<script>

import ClickToEdit from "@/components/ClickToEdit";
export default {
  name: 'element-name-table-column',
  components: {ClickToEdit},

  props: {
    scope: {type: Object, required: true},
    showFoldersInSearch: {type: Boolean, required: true},
  },

  data() {
    return {

    }
  },
  computed: {},
  methods: {

  }
}

</script>

<style>

</style>
